import React, { useState } from "react";
import * as Sentry from "@sentry/react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useDispatch } from "react-redux";
import ModalComponent from "../Modals/Modal";
import { useNavigate } from "react-router";
import { bookingIfCardNotSaved } from "../../store/actions/paymentActions";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "black",
      fontWeight: "500",
      fontFamily: "Roboto,Open Sans,Segeo UI,sans-serif",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "black" },
      ":-placeholder": { color: "black" },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "red",
    },
  },
};

const PaymentForm = ({ bookingObject, setShowLottie, submitWrapper }) => {
  // history
  const history = useNavigate();
  // stripe setup
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [modalFields, setModalFields] = useState({
    open: false,
    text: "",
    btnText1: "Ok",
    closeFunc: () => {
      setModalFields({ ...modalFields, open: false });
    },
  });

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const { error, token } = await stripe.createToken(
        elements.getElement(CardElement)
      );

      if (!error) {
        const { id } = token;
        dispatch(
          bookingIfCardNotSaved(
            bookingObject?.id,
            bookingObject?.type,
            history,
            id,
            modalFields,
            setModalFields,
            setShowLottie
          )
        );
        elements.getElement(CardElement).clear();
      } else {
        console.log("payment error", error.message);
        setShowLottie(false);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
      setShowLottie(false);
    }
  };

  return (
    <div>
      <ModalComponent
        text={modalFields.text}
        openState={modalFields.open}
        closeFunction={() => {
          setModalFields({ ...modalFields, open: false });
        }}
        btnText1={modalFields.btnText1}
        btnClickFunc1={modalFields.closeFunc}
      />
      <form
        id="payment-in-booking"
        onSubmit={(e) =>
          submitWrapper ? submitWrapper(handleSubmit, e) : handleSubmit(e)
        }
      >
        <fieldset>
          <div className="FormRow">
            <CardElement options={CARD_OPTIONS} />
          </div>
        </fieldset>
        {/* <input type="submit" value="save card"></input> */}
      </form>
    </div>
  );
};

export default PaymentForm;
