import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
// importing the css
import css from "../../style/booking.module.css";
// importing the components
// First Screen
import AppCards from "../../components/Booking/AppCards";
import BookingTypeDropdown from "../../components/Booking/BookingTypeDropdown";
import CalanderModal from "../../components/Booking/CalanderModal";
import Counter from "../../components/Booking/Counter";
import LoadSizeButtons from "../../components/Booking/LoadSize";
import Map from "../../components/Booking/Map";
import MapInput from "../../components/Booking/MapInput";
import MoveSize from "../../components/Booking/MoveSizeDropdown";
import MoveTypeDropdown from "../../components/Booking/MoveTypesDropdown";
import RoundButton from "../../components/Booking/RoundButton";
import Slider from "../../components/Booking/Slider";
import TotalHours from "../../components/Booking/TotalHours";
import Upload from "../../components/Booking/Upload";
import ModalComponent from "../../components/Modals/Modal";
import CTASection from "../../components/Booking/CTASection";
//Second Screen
import ConfirmDetails from "../../components/Booking/ConfirmDetails";
import RecipentInfo from "../../components/Booking/RecipentInfo";
import TotalConfirm from "../../components/Booking/TotalConfirm";
// Payment Screen
import PaymentScreen from "../../components/Booking/PaymentScreen";
// importing liberaries
import { useDispatch, useSelector } from "react-redux";
import { generateBookingLogs } from "../../Utilities/general";
//importing APIs
import { useNavigate } from "react-router";
import {
  getBookingTotal,
  getMovePlaces,
  getMoveSize,
  postBooking,
  updateBooking,
} from "../../store/actions/bookingActions";
import { getAllDeliverySlots } from "../../store/actions/deliverySlotsActions";
import { getAllVehicles } from "../../store/actions/vehicleTypeActions";
import LoadingLottie from "../../components/Lottie/LoadingLottie";
import { userPaymentMethods } from "../../store/actions/paymentActions";

// function to get the scren width
function useWindowSize() {
  const [size, setSize] = useState([]);
  useEffect(() => {
    try {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => {
        window.addEventListener("resize", handleResize);
      };
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, []);
  return size;
}

const Booking = () => {
  const history = useNavigate();
  const [total, setTotal] = useState();
  const [errorObject, setErrorObject] = useState({});
  const [bookingObject, setBookingObject] = useState({
    id: 0,
    pickupLocation: "",
    pickupLatitude: "",
    pickupLongitude: "",
    dropoffLocation: "",
    dropoffLatitude: "",
    dropoffLongitude: "",
    vehicleTypeId: 1002,
    deliveryDate: "",
    type: "Mooving",
    deliverySlotId: 1000,
    exactTime: "",
    labourNeeded: 1,
    totalOverweightItems: 0,
    estimatedTime: 0,
    pickupTotalFloors: 0,
    dropoffTotalFloors: 0,
    pickupMedium: "elevator",
    dropoffMedium: "elevator",
    distanceInKm: 0,
    durationInMins: 0,
    bookingSizeId: 1,
    dropOffPersonName: "",
    dropOffPersonPhone: "",
    pickUpPersonName: "",
    pickUpPersonPhone: "",
    dropOffInstructions: "",
    pickUpInstructions: "",
    tripStatus: "",
    userId: 0,
    numberOfHours: 1,
    stops: [],
    isDeliverNow: true,
  });

  //
  //
  // DEFINING
  // VARIABLES
  //
  //

  // dispatch redux actions
  const dispatch = useDispatch();

  const [vehicleId, setVehicleId] = useState(0);
  // move places
  const [movePlaces, setmovePlaces] = useState();
  // move size
  const [moveSize, setmoveSize] = useState();
  // getting the dimentions
  const [height, width] = useWindowSize();

  const [openCalander, setOpenCalander] = useState(false);

  const [showGenericModal, setShowGenericModal] = useState(false);
  const [genericModalMessage, setGenericModalMessage] = useState("");
  const [showLottie, setShowLottie] = useState(true);

  //
  //
  // GETTING STATES FROM REDUX
  //
  //
  // getting vehicle info
  const { vehicleTypesInfo } = useSelector(
    (state) => state.vehicleTypesReducer
  );
  // Getting delivery slots
  const { deliverySlotsInfo } = useSelector(
    (state) => state.deliverySlotsReducer
  );

  // getting user details from reducer
  const { loginInfo } = useSelector((state) => state.loginReducer);

  //Modal consts
  const [open, setOpen] = React.useState(false);
  const [confirm1Moover, setConfirm1Moover] = useState(false);
  const handleClose = () => setOpen(false);
  const [Dateopen, setDateOpen] = React.useState(false);
  const handleDateClose = () => setDateOpen(false);

  //
  //
  // FUNCTIONS
  //
  //
  //

  const handleUpdateBookingObject = (name, value, triggeredBy) => {
    try {
      const bookingTypeFromLS = localStorage.getItem("currentBookingType");
      let source = bookingObject;
      let temp;
      if (bookingTypeFromLS !== source.type && name !== "type") {
        localStorage.removeItem(`wemoovez${source.type}Object`);
        source = JSON.parse(
          localStorage.getItem(`wemoovez${bookingTypeFromLS}Object`)
        );
      }
      if (name) {
        temp = JSON.parse(JSON.stringify(source));
        temp[name] = value;
        if (name === "vehicle") temp.vehicleTypeId = value.id;
      } else {
        temp = JSON.parse(JSON.stringify(value));
      }
      if (name === "type") {
        localStorage.setItem("currentBookingType", value);
        if (value === "Moovers") {
          temp.dropoffLatitude = "";
          temp.dropoffLongitude = "";
          temp.dropoffLocation = "";
          temp.dropOffPersonName = "";
          temp.dropOffPersonPhone = "";
          temp.dropOffInstructions = "";
        }
      }
      setBookingObject(temp);
      localStorage.setItem(`wemoovez${temp.type}Object`, JSON.stringify(temp));
      generateBookingLogs(
        name,
        value,
        temp,
        triggeredBy,
        `wemoovez${temp.type}Object`
      );
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const handleCloseCalander = () => {
    try {
      setOpenCalander(false);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const handleAddDefaultDate = (tempDeliveryObj) => {
    try {
      tempDeliveryObj.deliveryDate = new Date()?.toString()?.split(" GMT")[0];
      tempDeliveryObj.isDeliverNow = true;
      tempDeliveryObj.exactTime = "";
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const handleToggleGenericError = (message) => {
    if (message === "confirm1Moover") return;
    setShowGenericModal(message ? true : false);
    setGenericModalMessage(message ? message : "");
  };

  const handleValidatePostBooking = (tempBookingObject) => {
    if (tempBookingObject.tripStatus === "Confirmation") return null;
    if (!tempBookingObject?.pickupLocation)
      return "Pickup location missing! please fill all the fields...";

    if (
      !tempBookingObject?.dropoffLocation &&
      tempBookingObject?.type !== "Moovers"
    )
      return "Drop off location missing! please fill all the fields...";

    if (
      !tempBookingObject?.vehicleTypeId &&
      tempBookingObject?.type !== "Moovers"
    )
      return "Vehicle type missing! please fill all the fields...";
    if (!tempBookingObject?.moveTypeId && tempBookingObject?.type === "Mooving")
      return "Move type missing! please fill all the fields...";
    if (!tempBookingObject?.moveSizeId && tempBookingObject?.type === "Mooving")
      return "Move size missing! please fill all the fields...";

    if (
      !tempBookingObject?.distanceInKm &&
      tempBookingObject?.type !== "Moovers"
    ) {
      return "Sorry, we cannot deliver on the following route. Please try again later.";
    }
    if (
      tempBookingObject?.labourNeeded < 2 &&
      !confirm1Moover &&
      !tempBookingObject.id
    ) {
      setOpen(true);
      return "confirm1Moover";
    }
  };

  const handleValidateConfirmBooking = () => {
    let errorCount = 0;
    let tempError = {};
    if (bookingObject.dropoffLocation && !bookingObject?.dropOffPersonName) {
      tempError.dropOffPersonName = true;
      errorCount += 1;
    }
    if (bookingObject.dropoffLocation && !bookingObject?.dropOffPersonPhone) {
      tempError.dropOffPersonPhone = true;
      errorCount += 1;
    }

    if (!bookingObject?.pickUpPersonName) {
      tempError.pickUpPersonName = true;
      errorCount += 1;
    }

    if (!bookingObject?.pickUpPersonPhone) {
      tempError.pickUpPersonPhone = true;
      errorCount += 1;
    }
    if (errorCount > 0) {
      setErrorObject(tempError);
      return "Please fill all the fields!";
    }
    if (bookingObject.pickUpPersonPhone.length !== 12) {
      tempError.pickUpPersonPhone = true;
      errorCount += 1;
    }
    if (
      bookingObject.dropoffLocation &&
      bookingObject.dropOffPersonPhone.length !== 12
    ) {
      tempError.dropOffPersonPhone = true;
      errorCount += 1;
    }
    if (errorCount > 0) {
      setErrorObject(tempError);
      return "Invalid phone number!";
    }
  };

  const handleBookNow = (saveDefaultDate) => {
    try {
      let tempBookingObj = {
        ...bookingObject,
        isDeliverNow: true,
      };
      let error = handleValidatePostBooking(tempBookingObj);
      if (error) return handleToggleGenericError(error);
      if (!loginInfo?.id) {
        localStorage.setItem("redirectToBooking", "true");
        history("/login");
        return;
      }
      tempBookingObj.tripStatus = "Confirmation";
      if (tempBookingObj.type === "Delivery") tempBookingObj.isHalfLoad = false;
      if (saveDefaultDate) handleAddDefaultDate(tempBookingObj);
      else tempBookingObj.isDeliverNow = false;
      setShowLottie(true);
      if (tempBookingObj.id)
        updateBooking(
          tempBookingObj?.id,
          {
            ...tempBookingObj,
            userId: loginInfo.id,
          },
          handleUpdateBookingObject,
          setShowLottie
        );
      else
        postBooking(
          {
            ...tempBookingObj,
            userId: loginInfo.id,
          },
          handleUpdateBookingObject,
          setShowLottie
        );
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
      setShowLottie(false);
    }
  };

  const handleProceedScheduleForLater = () => {
    try {
      let deliveryDate;
      if (bookingObject?.deliveryDate) {
        const selectedDate = new Date();
        const todayDate = new Date();
        todayDate.setHours(0, 0, 0, 0);
        if (selectedDate < todayDate) {
          setDateOpen(true);
          return;
        }
        deliveryDate = bookingObject?.deliveryDate;
      } else deliveryDate = new Date().toString()?.split(" GMT")[0];
      handleUpdateBookingObject("deliveryDate", deliveryDate, "10.2");
      setOpenCalander(false);
      let error = handleValidatePostBooking(bookingObject);
      if (error) return handleToggleGenericError(error);
      let tempBookingObj = {
        ...bookingObject,
        tripStatus: "Confirmation",
        deliveryDate,
      };
      setShowLottie(true);
      if (tempBookingObj.id)
        updateBooking(
          tempBookingObj?.id,
          {
            ...tempBookingObj,
            userId: loginInfo.id,
            isDeliverNow: false,
          },
          handleUpdateBookingObject,
          setShowLottie
        );
      else
        postBooking(
          {
            ...tempBookingObj,
            userId: loginInfo.id,
            isDeliverNow: false,
          },
          handleUpdateBookingObject,
          setShowLottie
        );
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
      setShowLottie(false);
    }
  };
  const handleScheduleForLater = () => {
    try {
      setOpenCalander(true);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  // checking the confirmation credentials
  const handleConfirmOrder = () => {
    try {
      let error = handleValidateConfirmBooking();
      if (error) return handleToggleGenericError(error);
      setShowLottie(true);
      dispatch(
        updateBooking(
          bookingObject.id,
          {
            ...bookingObject,
            tripStatus: "Payment",
          },
          handleUpdateBookingObject,
          setShowLottie
        )
      );
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
      setShowLottie(false);
    }
  };

  // edit booking
  const handleEditOrder = () => {
    try {
      setShowLottie(true);
      updateBooking(
        bookingObject?.id,
        {
          ...bookingObject,
          tripStatus: "Inprogress",
        },
        handleUpdateBookingObject,
        setShowLottie
      ).then(() => handleSelectVehicleInSlider());
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
      setShowLottie(false);
    }
  };

  //async function for confirming 1 moover
  const confirm1mooverfunc = () => {
    setConfirm1Moover(true);
    setOpen(false);
  };

  const handleReturnTotalParams = () => {
    let totalReqObj = {};
    const commonFields = ["type", "distanceInKm", "labourNeeded"];
    commonFields.forEach((key) => {
      if (!bookingObject[key]) return false;
      totalReqObj[key] = bookingObject[key];
    });
    totalReqObj = {
      ...totalReqObj,
      isHalfLoad: false,
      pickupMedium: "elevator",
      dropoffMedium: "elevator",
      id: 0,
      userId: 0,
      couponId: 0,
      coupon: null,
      discount: 0,
      moveSizeId: 0,
      vehicleTypeId: bookingObject.vehicleTypeId,
      numberOfHours: 0,
      stops: bookingObject?.stops?.length ?? 0,
    };
    if (!bookingObject.durationInMins) return false;
    totalReqObj.totalDuration = bookingObject.durationInMins;

    if (bookingObject.type === "Mooving") {
      if (!bookingObject.vehicleTypeId || !bookingObject.moveSizeId)
        return false;
      return {
        ...totalReqObj,
        moveSizeId: bookingObject.moveSizeId,
      };
    }
    if (bookingObject.type === "Moovers") {
      if (!bookingObject.numberOfHours) return false;
      return {
        ...totalReqObj,
        numberOfHours: bookingObject.numberOfHours,
        vehicleTypeId: 0,
      };
    }
    if (bookingObject.type === "Delivery") {
      if (!bookingObject.vehicleTypeId) return false;
      return {
        ...totalReqObj,
      };
    }
    if (bookingObject.type === "Business") {
      if (!bookingObject.vehicleTypeId || !bookingObject.isHalfLoad)
        return false;
      return {
        ...totalReqObj,
        isHalfLoad: bookingObject.isHalfLoad,
      };
    }
    return false;
  };

  const get1MoverModalTxt = () => {
    if (bookingObject.type === "Mooving")
      return "Are you sure 1 mover is enough? If this move involves big items, please consider 2 movers OR we'll need your help to load and unload the items.";
    else if (bookingObject.type === "Moovers")
      return "Are you sure 1 mover is enough? If you need big items moved, please consider 2 movers OR require need your help.";
    return "Are you sure 1 mover is enough? If it's a big item make sure to select 2 movers OR we'll need your help to load and unload.";
  };

  const handleChangeMoveType = (val) => {
    try {
      const id = val ? parseInt(val) : 0;
      if (!id) return;
      handleUpdateBookingObject(
        null,
        {
          ...bookingObject,
          moveTypeId: id,
          moveSizeId: null,
        },
        "1"
      );
      dispatch(getMoveSize(id, setmoveSize));
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const updateUserCardStatus = () => {
    try {
      let user = localStorage.getItem("wemoovezUserProfile");
      if (user) {
        user = JSON.parse(user);
        userPaymentMethods(user.id);
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const handleSelectVehicleInSlider = () => {
    const index = vehicleTypesInfo?.findIndex(
      (vehicle) => vehicle?.id === vehicleId
    );
    const ch_id = index >= 0 ? index : 0;
    let theDot = document.getElementById(`vehicleSliderDot${ch_id}`).click();
  };

  //
  //
  // USE
  // EFFECT
  // ACTIONS
  //
  //

  // populate delivery Object from reducer
  useEffect(() => {
    try {
      updateUserCardStatus();
      const bookingTypeFromLS = localStorage.getItem("currentBookingType");
      if (!bookingTypeFromLS)
        localStorage.setItem("currentBookingType", "Mooving");
      else {
        let temp = localStorage.getItem(`wemoovez${bookingTypeFromLS}Object`);
        if (temp) {
          temp = JSON.parse(temp);
          if (temp?.type && temp?.type === bookingTypeFromLS) {
            window.setTimeout(() => setBookingObject(temp), 100);
            setVehicleId(temp?.vehicleTypeId);
            if (temp?.moveTypeId)
              dispatch(getMoveSize(temp?.moveTypeId, setmoveSize));
          } else {
            localStorage.removeItem(`wemoovez${bookingTypeFromLS}Object`);
            localStorage.setItem("currentBookingType", "Mooving");
          }
        } else {
          const bookingWIthUpdatedType = {
            ...bookingObject,
            type: bookingTypeFromLS,
          };
          generateBookingLogs(
            null,
            bookingWIthUpdatedType,
            "22",
            `wemoovez${bookingTypeFromLS}Object`
          );
          localStorage.setItem(
            `wemoovez${bookingTypeFromLS}Object`,
            JSON.stringify(bookingWIthUpdatedType)
          );
          window.setTimeout(
            () => setBookingObject(bookingWIthUpdatedType),
            100
          );
        }
      }
      // call all the APIs
      dispatch(getAllVehicles());
      dispatch(getAllDeliverySlots());
      dispatch(getMovePlaces(setmovePlaces));
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
    const hideLottie = setTimeout(() => {
      setShowLottie(false);
    }, 3000);
    return () => clearTimeout(hideLottie);
  }, []);

  useEffect(() => {
    try {
      // CALCULATING TOTAL WHENEVER THERE IS A CHANGE
      let calculateTotal;
      const totalReqParams = handleReturnTotalParams();
      if (totalReqParams) {
        calculateTotal = setTimeout(() => {
          getBookingTotal(totalReqParams, setTotal);
        }, 2000);
      } else {
        setTotal(null);
      }
      return () => clearTimeout(calculateTotal);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [bookingObject]);

  // useEffect to update vehicle counter
  useEffect(() => {
    try {
      if (vehicleTypesInfo?.length) {
        if (vehicleId) {
          handleSelectVehicleInSlider();
        } else {
          handleUpdateBookingObject(
            "vehicleTypeId",
            vehicleTypesInfo[0].id,
            "2"
          );
          let theDot = document.getElementById(`vehicleSliderDot0`).click();
        }
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [vehicleTypesInfo, vehicleId]);

  // useEffect post booking if user confirms 1 mover
  useEffect(() => {
    try {
      if (confirm1Moover) handleBookNow();
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [confirm1Moover]);
  return (
    <div>
      <CalanderModal
        deliveryDate={bookingObject?.deliveryDate}
        bookingObject={bookingObject}
        handleUpdateBookingObject={handleUpdateBookingObject}
        openState={openCalander}
        closeFunction={handleCloseCalander}
        btnClickFunc1={handleProceedScheduleForLater}
        timeSlots={deliverySlotsInfo}
      />
      <ModalComponent
        text={get1MoverModalTxt()}
        openState={open}
        closeFunction={handleClose}
        btnText1={"Yes, I am sure"}
        btnClickFunc1={() => confirm1mooverfunc()}
        btnText2={"Edit Booking"}
        btnClickFunc2={() => setOpen(false)}
      />
      <ModalComponent
        text={"Please Enter Current or any Future Date.."}
        openState={Dateopen}
        closeFunction={handleDateClose}
        btnText1={"Ok"}
        btnClickFunc1={() => {
          setDateOpen(false);
        }}
      />
      <ModalComponent
        text={genericModalMessage}
        openState={showGenericModal}
        closeFunction={() => handleToggleGenericError()}
        btnText1={"Okay"}
        btnClickFunc1={() => handleToggleGenericError()}
      />
      {showLottie ? <LoadingLottie /> : <></>}
      {bookingObject?.tripStatus === "" ||
      bookingObject?.tripStatus === "Inprogress" ? (
        <div className={css?.BookingMainDiv}>
          <div className={css?.bookingTopDiv}>
            <div className={css?.bookingLeftDiv}>
              <div className={css?.TopHeading}>
                Schedule your{" "}
                {bookingObject.type === "Delivery" ? "delivery" : "move"}
              </div>
              <BookingTypeDropdown
                otherTypes={["Mooving", "Moovers", "Delivery", "Business"]}
                handleUpdateBookingObject={handleUpdateBookingObject}
              />
              {["Mooving", "Delivery", "Business"].includes(
                bookingObject.type
              ) &&
              Array.isArray(vehicleTypesInfo) &&
              vehicleTypesInfo?.length ? (
                <Slider
                  vehicles={vehicleTypesInfo}
                  bookingObject={bookingObject}
                  handleUpdateBookingObject={handleUpdateBookingObject}
                  setVehicleId={setVehicleId}
                  vehicleId={vehicleId}
                />
              ) : (
                <></>
              )}
              <MapInput
                bookingObject={bookingObject}
                handleUpdateBookingObject={handleUpdateBookingObject}
              />
              {width < 601 && (
                <div className={css?.bookingRigthDiv}>
                  <Map
                    bookingObject={bookingObject}
                    handleUpdateBookingObject={handleUpdateBookingObject}
                  />
                </div>
              )}
              {bookingObject.type === "Business" && (
                <LoadSizeButtons
                  options={["1/2", "Full"]}
                  bookingObject={bookingObject}
                  handleUpdateBookingObject={handleUpdateBookingObject}
                />
              )}
              <Counter
                value={bookingObject?.labourNeeded}
                max={4}
                min={1}
                bookingObject={bookingObject}
                increment={() =>
                  handleUpdateBookingObject(
                    "labourNeeded",
                    bookingObject?.labourNeeded + 1,
                    "3"
                  )
                }
                decrement={() =>
                  handleUpdateBookingObject(
                    "labourNeeded",
                    bookingObject?.labourNeeded - 1,
                    "4"
                  )
                }
              />

              {bookingObject.type === "Moovers" && (
                <TotalHours
                  hours={bookingObject?.numberOfHours}
                  bookingObject={bookingObject}
                  max={10}
                  min={1}
                  increment={() =>
                    handleUpdateBookingObject(
                      "numberOfHours",
                      bookingObject?.numberOfHours + 1,
                      "5"
                    )
                  }
                  increment2={() =>
                    handleUpdateBookingObject(
                      "numberOfHours",
                      bookingObject?.numberOfHours + 1,
                      "6"
                    )
                  }
                  decrement={() =>
                    handleUpdateBookingObject(
                      "numberOfHours",
                      bookingObject?.numberOfHours - 1,
                      "7"
                    )
                  }
                />
              )}
              {["Mooving"].includes(bookingObject.type) &&
              movePlaces?.length ? (
                <MoveTypeDropdown
                  // Backend is sending move type in move place API
                  values={movePlaces}
                  bookingObject={bookingObject}
                  clickFunction={handleChangeMoveType}
                />
              ) : (
                <></>
              )}
              {["Mooving"].includes(bookingObject.type) && moveSize?.length ? (
                <MoveSize
                  values={moveSize}
                  handleUpdateBookingObject={handleUpdateBookingObject}
                  bookingObject={bookingObject}
                />
              ) : (
                <></>
              )}
              {width < 600 && (
                <CTASection
                  bookingObject={bookingObject}
                  total={total}
                  handleBookNow={handleBookNow}
                  handleScheduleForLater={handleScheduleForLater}
                />
              )}
            </div>
            {width > 600 && (
              <div className={css?.bookingRigthDiv}>
                <Map
                  bookingObject={bookingObject}
                  handleUpdateBookingObject={handleUpdateBookingObject}
                  height={total ? 160 : 70}
                />
                <div style={{ padding: 10, color: "#f2f2f2" }}>
                  <CTASection
                    bookingObject={bookingObject}
                    total={total}
                    handleBookNow={handleBookNow}
                    handleScheduleForLater={handleScheduleForLater}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={css?.bookingBottomDiv}>
            <AppCards />
          </div>
        </div>
      ) : bookingObject?.tripStatus === "Confirmation" ? (
        <div className={css?.BookingMainDiv}>
          <ConfirmDetails
            bookingObject={bookingObject}
            movePlaces={movePlaces}
            moveSize={moveSize}
          />
          <RecipentInfo
            errorObject={errorObject}
            setErrorObject={setErrorObject}
            bookingObject={bookingObject}
            handleUpdateBookingObject={handleUpdateBookingObject}
          />
          <div className={css?.confirmBottomDiv}>
            <div className={css?.confirmBottomLeftDiv}>
              <Upload
                bookingObject={bookingObject}
                handleUpdateBookingObject={handleUpdateBookingObject}
              />
            </div>
            <div className={css?.confirmBottomRightDiv}>
              <TotalConfirm bookingObject={bookingObject} />
              <div className={css?.CTA_BTN} onClick={handleConfirmOrder}>
                Proceed
              </div>
              <div className={css?.CTA_BTN} onClick={handleEditOrder}>
                Edit Booking
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={css?.BookingMainDiv}>
          <PaymentScreen
            bookingObject={bookingObject}
            handleUpdateBookingObject={handleUpdateBookingObject}
            userInfo={loginInfo}
            setShowLottie={setShowLottie}
          />
        </div>
      )}
    </div>
  );
};

export default Booking;
