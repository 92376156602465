import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGIN_REQUEST,
  LOGOUT,
} from "../constants/LoginConstants";
import {
  GET_USER_PROFILE_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_REQUEST,
} from "../constants/userProfileConstants";

// used for post content
export const loginReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loginInfo: null,
        loginLoading: true,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("wemoovezUserProfile", JSON.stringify(payload));
      return {
        ...state,
        loginLoading: false,
        loginInfo: payload,
        loginError: "",
      };
    case LOGIN_FAIL:
      console.log("error is here", payload);
      return {
        ...state,
        loginLoading: false,
        loginError: payload,
      };
    case LOGOUT:
      localStorage.removeItem("wemoovezUserProfile");
      localStorage.removeItem("wemoovezMooversObject");
      localStorage.removeItem("wemoovezDeliveryObject");
      localStorage.removeItem("wemoovezMoovingObject");
      localStorage.removeItem("wemoovezMooversObjectLog");
      localStorage.removeItem("wemoovezDeliveryObjectLog");
      localStorage.removeItem("wemoovezMoovingObjectLog");
      return {
        ...state,
        loginInfo: null,
        loginLoading: false,
        loginError: null,
      };
    case GET_USER_PROFILE_REQUEST:
      return {
        ...state,
        loginInfo: null,
        userProfileLoading: true,
      };
    case GET_USER_PROFILE_SUCCESS:
      localStorage.setItem("wemoovezUserProfile", JSON.stringify(payload));
      return {
        ...state,
        userProfileLoading: false,
        loginInfo: payload,
        userProfileError: "",
      };
    case GET_USER_PROFILE_FAIL:
      return {
        ...state,
        userProfileLoading: false,
        userProfileError: payload,
      };
    default:
      return state;
  }
};
