import React, { useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useNavigate, useParams } from "react-router";
import {
  getSingleBooking,
  cancelBooking,
} from "../../store/actions/bookingActions";
import { useDispatch, useSelector } from "react-redux";
import css from "../../style/singleTrip.module.css";
import MapComponent from "../../components/Booking/Map";
import { CircularProgress } from "@material-ui/core";
import RoundButton from "../../components/Booking/RoundButton";
import cssBooking from "../../style/booking.module.css";
import { AiOutlineDownload } from "react-icons/ai";
import { bookingFilesImageURL } from "../../store/constants/baseUrl";
//Modal Imports
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const SingleTrip = () => {
  const [bookingObj, setbookingObj] = useState();
  //   stops length
  const [stopLength, setstopLength] = useState(0);

  //Modal Consts

  const [open, setOpen] = React.useState(false);
  const [confirm1Moover, setConfirm1Moover] = useState(false);
  const handleClose = () => setOpen(false);
  //loader
  const [loader, setLoader] = useState(false);

  // dispatch actions
  const dispatch = useDispatch();
  // history for routing
  const history = useNavigate();
  // getting params
  const { userId, bookingId } = useParams();

  // getting booking details from reducer
  const { bookingInfo } = useSelector((state) => state.bookingReducer);
  const stateV = useSelector((state) => state);
  console.log("stateV", stateV);
  // getting booking
  useEffect(() => {
    dispatch(getSingleBooking(bookingId));
  }, []);

  //populating booking Obj
  useEffect(() => {
    try {
      if (bookingInfo) {
        setbookingObj(bookingInfo);
        setstopLength(bookingInfo?.stops?.length);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [bookingInfo]);

  const handleCancelBooking = () => {
    try {
      setLoader(true);
      dispatch(cancelBooking(bookingId, userId, setLoader, history));
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const handleEditBooking = () => {
    try {
      if (
        ["Delivery", "Mooving", "Moovers", "business"].includes(
          bookingInfo.type
        )
      ) {
        localStorage.setItem("currentBookingType", bookingInfo.type);
        localStorage.setItem(
          `wemoovez${bookingInfo.type}Object`,
          JSON.stringify(bookingInfo)
        );
        history({
          pathname: `/booking`,
          state: { bookingType: bookingInfo.type },
        });
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };
  //async function for confirming 1 moover
  const confirm1mooverfunc = async () => {
    setConfirm1Moover(true);
  };

  //Modal styles
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const MyDot = ({ isActive }) => (
    <span
      style={{
        display: "inline-block",
        height: isActive ? "8px" : "5px",
        width: isActive ? "8px" : "5px",
        background: "#1890ff",
      }}
    ></span>
  );

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: "center", color: "orange" }}
          >
            Warning !
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure to cancel your booking ? This action is Irreversible.
          </Typography>

          <div style={{ marginLeft: "12%" }}>
            <div
              className={cssBooking?.roundBtn}
              onClick={() => {
                confirm1mooverfunc();
                handleCancelBooking();
              }}
            >
              Yes, I am sure
            </div>

            <RoundButton text="No" clickFunction={() => setOpen(false)} />
          </div>
          {loader ? (
            <div style={{ textAlignLast: "center", paddingTop: "10%" }}>
              <Box>
                <CircularProgress color="secondary" />
              </Box>
            </div>
          ) : (
            <div></div>
          )}
        </Box>
      </Modal>

      {bookingObj ? (
        <div className={css?.tripsMainDiv}>
          <div className={css?.tripsLeftDiv}>
            <div
              className={css?.tripsHeading}
              onClick={() => history(`/myTrips/${userId}`)}
            >
              <span className={css?.backButton}>
                <i class="fas fa-chevron-circle-left"></i>
              </span>
              my trips
            </div>
          </div>
          <div className={css?.tripsRightDiv}>
            <div className={css?.tripsTopLine}>
              <div className={css?.tripsHeading}>today's trip details</div>
            </div>
            <div className={css?.TripCard}>
              <div className={css?.TripMap}>
                <MapComponent
                  bookingObject={bookingObj}
                  setBookingObject={(nothing) => {
                    return;
                  }}
                />
              </div>
              <div className={css?.TripCardWhite}>
                <div className={css?.TripCardFirstLine}>
                  <div className={css?.TripCardFirstLineBox}>
                    <div className={css?.TripCardFirstLineBoxLabel}>
                      <span className={css?.TripCardFirstLineBoxLabelIcon}>
                        <i class="fas fa-calendar-day"></i>
                      </span>
                      <span className={css?.TripCardFirstLineBoxLabelText}>
                        Date
                      </span>
                    </div>
                    <div className={css?.TripCardFirstLineBoxData}>
                      {bookingObj?.deliveryDate?.split(" ")[0]}
                    </div>
                  </div>
                  <div className={css?.TripCardFirstLineBox}>
                    <div className={css?.TripCardFirstLineBoxLabel}>
                      <span className={css?.TripCardFirstLineBoxLabelIcon}>
                        <i class="fas fa-clock"></i>
                      </span>
                      <span className={css?.TripCardFirstLineBoxLabelText}>
                        Time
                      </span>
                    </div>
                    <div className={css?.TripCardFirstLineBoxData}>
                      {bookingObj?.exactTime}
                    </div>
                  </div>
                  <div className={css?.TripCardFirstLineBox}>
                    <div className={css?.TripCardFirstLineBoxLabel}>
                      <span className={css?.TripCardFirstLineBoxLabelIcon}>
                        <i class="fas fa-map-marker-alt"></i>
                      </span>
                      <span className={css?.TripCardFirstLineBoxLabelText}>
                        Pickup
                      </span>
                    </div>
                    <div className={css?.TripCardFirstLineBoxData}>
                      {bookingObj?.pickupLocation}
                    </div>
                  </div>
                  <div className={css?.TripCardFirstLineBox}>
                    <div className={css?.TripCardFirstLineBoxLabel}>
                      <span className={css?.TripCardFirstLineBoxLabelIcon}>
                        <i class="fas fa-map-marker-alt"></i>
                      </span>
                      <span className={css?.TripCardFirstLineBoxLabelText}>
                        DropOff
                      </span>
                    </div>
                    <div className={css?.TripCardFirstLineBoxData}>
                      {bookingObj?.dropoffLocation}
                    </div>
                  </div>
                </div>
                <div className={css?.TripCardSecondLine}>
                  <div className={css?.TripCardSecondLineBox}>
                    <div className={css?.TripCardSecondLineBoxLabel}>
                      <span className={css?.TripCardSecondLineBoxLabelIcon}>
                        <i class="fas fa-map-marker-alt"></i>
                      </span>
                      <span className={css?.TripCardSecondLineBoxLabelText}>
                        Pickup-Address
                      </span>
                    </div>
                    <div className={css?.TripCardSecondLineBoxData}>
                      {bookingObj?.pickupLocation}
                    </div>
                  </div>
                  <div className={css?.TripCardSecondLineBox}>
                    <div className={css?.TripCardSecondLineBoxLabel}>
                      <span className={css?.TripCardSecondLineBoxLabelIcon}>
                        <i class="fas fa-map-marker-alt"></i>
                      </span>
                      <span className={css?.TripCardSecondLineBoxLabelText}>
                        Drop-off-Address
                      </span>
                    </div>
                    <div className={css?.TripCardSecondLineBoxData}>
                      {bookingObj?.dropoffLocation}
                    </div>
                  </div>
                </div>
                <div className={css?.TripCardThirdLine}>
                  <div className={css?.TripCardThirdLineAddress}>
                    <div className={css?.TripCardThirdLineAddressIcon}>
                      <i class="fas fa-map-marker-alt"></i>
                    </div>
                    <div
                      className={css?.TripCardThirdLineAddressLabel}
                    >{`A instructions:`}</div>
                    <div className={css?.TripCardThirdLineAddressText}>
                      {bookingObj?.pickUpInstructions}
                    </div>
                  </div>
                  {bookingObj?.stops?.map((stop, index) => (
                    <div className={css?.TripCardThirdLineAddress}>
                      <div className={css?.TripCardThirdLineAddressIcon}>
                        <i class="fas fa-map-pin"></i>
                      </div>
                      <div
                        className={css?.TripCardThirdLineAddressLabel}
                      >{`${String.fromCharCode(
                        index + 66
                      )} instructions:`}</div>
                      <div className={css?.TripCardThirdLineAddressText}>
                        {bookingObj?.stops[index]?.instructions}
                      </div>
                    </div>
                  ))}
                  <div className={css?.TripCardThirdLineAddress}>
                    <div className={css?.TripCardThirdLineAddressIcon}>
                      <i class="fas fa-map-marker-alt"></i>
                    </div>
                    <div className={css?.TripCardThirdLineAddressLabel}>
                      {`
                  ${String.fromCharCode(stopLength + 66)}
                   instructions:`}
                    </div>
                    <div className={css?.TripCardThirdLineAddressText}>
                      {bookingObj?.dropOffInstructions}
                    </div>
                  </div>
                </div>
                {/* =========================================== */}

                <div className={css?.TripCardFourthLineFileOne}>
                  {bookingObj?.bookingFiles?.map((file, index) =>
                    file?.type?.split("/")[0] === "image" ? (
                      <div
                        style={
                          {
                            // display: "flex",
                            // justifyContent: "left",
                            // alignItems: "center",
                            // marginLeft: 22,
                            // width: "90%",
                            // flexDirection: "column",
                          }
                        }
                        // onclick={() => {filterImageFiles(i?.id)}}
                        className={css?.TripCardFourthLineFileInner}
                      >
                        <img
                          src={`${bookingFilesImageURL}${file?.fileUrl}`}
                          alt={`image #${index}`}
                          style={{ height: 70, width: 90 }}
                        />
                        <p>{file?.fileUrl}</p>
                        <a
                          href={`${bookingFilesImageURL}${file?.fileUrl}`}
                          download
                          style={{ height: 50, width: 50 }}
                        >
                          <AiOutlineDownload
                            style={{
                              color: "red",
                              paddingLeft: 15,
                              paddingBottom: 10,
                              cursor: "pointer",
                              marginLeft: 25,
                            }}
                            size={30}
                            onClick={() => {}}
                          />
                        </a>
                      </div>
                    ) : null
                  )}
                </div>
                {/* 
                <div className={css?.TripCardFourthLineFileOne}>
                  <Carousel
                    cols={2}
                    rows={1}
                    gap={3}
                    loop
                    scrollSnap={true}
                    dot={MyDot}
                  >
                    {bookingObj?.bookingFiles?.map((file, index) =>
                      file?.type?.split("/")[0] === "image" ? (
                        <Carousel.Item>
                          <div style={{ marginLeft: 50 }}>
                            <div
                              style={{
                                width: "20%",
                                height: "20%",
                                backgroundColor: "green",
                              }}
                            >
                              <img
                                src={`${bookingFilesImageURL}${file?.fileUrl}`}
                              />
                            </div>
                            <p style={{ marginTop: 10 }}>{file?.fileUrl}</p>
                            <a
                              href={`${bookingFilesImageURL}${file?.fileUrl}`}
                              download
                              style={{ height: 50, width: 50 }}
                            >
                              <AiOutlineDownload
                                style={{
                                  color: "red",
                                  paddingLeft: 15,
                                  paddingBottom: 10,
                                  cursor: "pointer",
                                  marginLeft: 200,
                                }}
                                size={30}
                                onClick={() => {}}
                              />
                            </a>
                          </div>
                        </Carousel.Item>
                      ) : null
                    )}
                  </Carousel>
                </div> */}

                {/* ========================================== */}
                <div className={css?.TripCardFourthLine}>
                  <div className={css?.TripCardFourthLineBox}>
                    <div className={css?.TripCardFourthLineBoxLabel}>
                      <span className={css?.TripCardFourthLineBoxLabelIcon}>
                        <i class="fas fa-briefcase"></i>
                      </span>
                      <span className={css?.TripCardFourthLineBoxLabelText}>
                        Movers
                      </span>
                    </div>
                    <div className={css?.TripCardFourthLineBoxData}>
                      {bookingObj?.labourNeeded
                        ? bookingObj?.labourNeeded
                        : "No Movers"}
                    </div>
                  </div>
                  <div className={css?.TripCardFourthLineBox}>
                    <div className={css?.TripCardFourthLineBoxLabel}>
                      <span className={css?.TripCardFourthLineBoxLabelIcon}>
                        <i class="fas fa-truck-moving"></i>
                      </span>
                      <span className={css?.TripCardFourthLineBoxLabelText}>
                        Vehicle
                      </span>
                    </div>
                    <div className={css?.TripCardFourthLineBoxData}>
                      {bookingObj?.vehicle?.title}
                    </div>
                  </div>
                  <div className={css?.TripCardFourthLineBox}>
                    <div className={css?.TripCardFourthLineBoxLabel}>
                      <span className={css?.TripCardFourthLineBoxLabelIcon}>
                        <i class="fas fa-wallet"></i>
                      </span>
                      <span className={css?.TripCardFourthLineBoxLabelText}>
                        Est. Cost
                      </span>
                    </div>
                    <div className={css?.TripCardFourthLineBoxData}>
                      {bookingInfo?.bookingTotalModel?.totalCharge}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button className={css?.cancelPickup} onClick={() => setOpen(true)}>
              Cancel Pickup
            </button>
            {/* <button className={css?.EditTripBtn} onClick={handleEditBooking}>
              Edit Trip
            </button> */}
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "40vh",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default SingleTrip;
