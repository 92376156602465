import React from "react";
import * as Sentry from "@sentry/react";
import Layout from "../layouts/index";
import UserProfile from "../screens/Account/UserProfile";

const UserProfilePage = () => {
  return (
    <Layout>
      <UserProfile />
    </Layout>
  );
};

export default UserProfilePage;
