import React from "react";
import css from "../../style/booking.module.css";
import TotalConfirm from "./TotalS1";
import { Margin } from "@mui/icons-material";

const CTASection = ({bookingObject,total,handleBookNow,handleScheduleForLater}) => {
  return (
    <div className={css?.CTA_SECTION}>
      {total?.totalCharge && (
        <TotalConfirm bookingObject={bookingObject} total={total} />
      )}
      <div className={css?.bookingButtonsGroup}>
        {["Delivery", "Moovers"].includes(bookingObject?.type) ? (
          <div className={css?.CTA_BTN} onClick={() => handleBookNow(true)}>
            {bookingObject?.type == "Delivery" ? "Deliver now" : "Book now"}
          </div>
        ) : (
          <></>
        )}
        <div className={css?.CTA_BTN} style={{margin:"auto"}} onClick={handleScheduleForLater}>
          {/* Schedule a move */}
          {["Delivery", "Moovers"].includes(bookingObject?.type)
            ? "Schedule for later"
            : "Schedule a move"}
        </div>
      </div>
    </div>
  );
};

export default CTASection;
