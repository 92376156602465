import axios from "axios";
import * as Sentry from "@sentry/react";
import baseUrl from "../constants/baseUrl";

export const postFeedback =
  (feedback, setError, history) => async (dispatch) => {
    try {
      await axios.post(`${baseUrl}Feedback/FeedbackFromUser`, feedback);
      history("/booking");
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
      setError("Failed to load feedback");
    }
  };

export const getFeedbackOptions = (setFeedbackOptions) => async (dispatch) => {
  try {
    const { data } = await axios.get(`${baseUrl}Feedback/GetFeedback`);
    setFeedbackOptions(data);
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
  }
};

export const getTipOptions = (setTipOptions) => async (dispatch) => {
  try {
    const { data } = await axios.get(`${baseUrl}Feedback/GetTip`);
    setTipOptions(data);
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
  }
};
