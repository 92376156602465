import React, { useEffect, useState, Fragment } from "react";
import * as Sentry from "@sentry/react";
// importing the css
import css from "../../style/booking.module.css";
import { MdLocationOn } from "react-icons/md";
import { FaCalendarAlt, FaClock } from "react-icons/fa";
import { FaCar } from "react-icons/fa";
import ConfirmDetailsCard from "./ConfirmDetailsCard";

const ConfirmDetails = ({ bookingObject, movePlaces, moveSize }) => {
  const [cardsArr, setCardsArr] = useState();

  const returnValueAgainstId = (arr, id, propertyId, propertyVal) => {
    if (!arr?.length) return "--";
    let item = arr.find(
      (data) => data[propertyId] === id || data[propertyId] === parseInt(id)
    );
    if (!item || !item[propertyVal]) return "--";
    return item[propertyVal];
  };

  const returnPlaceholderForPickup = () => {
    try {
      return bookingObject.type === "Moovers"
        ? "Address #1"
        : "Pickup location";
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const returnPlaceholderForDropoff = () => {
    try {
      if (bookingObject.type !== "Moovers") return "Drop off location";
      if (bookingObject?.stops?.length)
        return `Address #${bookingObject.stops.length + 2}`;
      return `Address #2`;
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const returnPlaceholderForStop = (index) => {
    try {
      if (bookingObject.type !== "Moovers")
        return `Pickup location ${index + 2}`;
      return `Address #${index + 2}`;
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const handleReturnStops = () => {
    if (Array.isArray(bookingObject.stops) && bookingObject.stops.length) {
      return bookingObject.stops
        .filter((stop) => stop?.stop)
        .map((stop, index) => ({
          heading: returnPlaceholderForStop(index),
          detail: stop.stop,
          Icon: (
            <Fragment>
              <MdLocationOn className={css?.confirmIcon}></MdLocationOn>
            </Fragment>
          ),
        }));
    }
    return [];
  };

  useEffect(() => {
    try {
      if (bookingObject) {
        let cardArr = [
          {
            heading: returnPlaceholderForPickup(),
            detail: bookingObject?.pickupLocation,
            Icon: (
              <Fragment>
                <MdLocationOn className={css?.confirmIcon}></MdLocationOn>
              </Fragment>
            ),
          },
          ...handleReturnStops(),
          {
            heading: returnPlaceholderForDropoff(),
            detail: bookingObject?.dropoffLocation,
            Icon: (
              <Fragment>
                <MdLocationOn className={css?.confirmIcon}></MdLocationOn>
              </Fragment>
            ),
          },
          {
            heading: "Pickup date",
            detail: bookingObject?.deliveryDate
              ? bookingObject?.deliveryDate.split(" ")[0]
              : "",
            Icon: (
              <Fragment>
                <FaCalendarAlt className={css?.confirmIcon}></FaCalendarAlt>
              </Fragment>
            ),
          },
          {
            heading: "Vehicle",
            detail: bookingObject?.vehicle?.title,
            Icon: (
              <Fragment>
                <FaCar className={css?.confirmIcon}></FaCar>
              </Fragment>
            ),
          },
          {
            heading: <div># of movers </div>,
            detail:
              bookingObject?.labourNeeded !== undefined
                ? bookingObject?.labourNeeded
                : 1,
            Icon: (
              <Fragment>
                <img
                  src="images/number_movers.svg"
                  className={css?.confirmIconImg}
                  alt=""
                />
              </Fragment>
            ),
          },
        ];
        if (["Mooving"].includes(bookingObject.type)) {
          cardArr = [
            ...cardArr,
            {
              heading: "Move Type",
              detail: returnValueAgainstId(
                movePlaces,
                bookingObject?.moveTypeId,
                "id",
                "place"
              ),
              Icon: (
                <Fragment>
                  <img
                    src="images/move_type.svg"
                    alt=""
                    className={css?.confirmIconImg}
                  />
                </Fragment>
              ),
            },
            {
              heading: "Move size",
              detail: returnValueAgainstId(
                moveSize,
                bookingObject?.moveSizeId,
                "id",
                "noOfBedrooms"
              ),
              Icon: (
                <Fragment>
                  <img
                    src="images/move_size.svg"
                    alt=""
                    className={css?.confirmIconImg}
                  />
                </Fragment>
              ),
            },
          ];
        }
        if (["Delivery"].includes(bookingObject.type)) {
          cardArr = [
            ...cardArr,
            {
              heading: "Pickup time",
              detail: bookingObject.isDeliverNow
                ? "Deliver now"
                : bookingObject.exactTime,
              Icon: (
                <Fragment>
                  <FaClock className={css?.confirmIcon}></FaClock>
                </Fragment>
              ),
            },
          ];
        }
        if (["Moovers"].includes(bookingObject.type)) {
          cardArr = cardArr.filter((card) => card.heading !== "Vehicle");
        }
        setCardsArr(cardArr);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, [bookingObject]);
  return (
    <div className={css?.confirmDetailsMainDiv}>
      <div className={css?.TopHeading} style={{ width: "100%" }}>
        Confirm details
      </div>
      {cardsArr
        ?.filter((card) => card.detail)
        ?.map((card) => (
          <ConfirmDetailsCard
            heading={card.heading}
            detail={card.detail}
            Icon={card.Icon}
          />
        ))}
    </div>
  );
};

export default ConfirmDetails;
